/* styles.css */
body {
  background-color: #f8f8f8;
  margin: 0px;
}
.h3 {
  background-color: #f8f8f8;
}
.modal-typo {
  text-transform: capitalize;
  opacity: "0.8";
  color: black;
  /* alignItems: "center", */
}

.highcharts-figure,
.highcharts-data-table table {
  min-width: 360px;
  max-width: 820px;
  margin: 1em auto;
}

.highcharts-data-table table {
  font-family: Verdana, sans-serif;
  border-collapse: collapse;
  border: 1px solid #ebebeb;
  margin: 10px auto;
  text-align: center;
  width: 100%;
  max-width: 500px;
}

.highcharts-data-table caption {
  padding: 1em 0;
  font-size: 1.2em;
  color: #555;
}

.highcharts-data-table th {
  font-weight: 600;
  padding: 0.5em;
}

.highcharts-data-table td,
.highcharts-data-table th,
.highcharts-data-table caption {
  padding: 0.5em;
}

.highcharts-data-table thead th,
.highcharts-data-table tbody th {
  font-size: small;
}

.highcharts-data-table thead tr {
  background: #f6f8fc;
  color: #000000;
}
.highcharts-data-table tr:nth-child(even) {
  background: #e9f1ff;
  color: #000000;
}

.highcharts-data-table tbody tr:hover {
  background: #f1f7ff;
}
