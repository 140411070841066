.error-text {
    display: flex;
    justify-content: space-between;
    padding: 0.8%;
}

.err-code {
 
    padding: 0.8%;
}

.error-tex {
    display: flex;
    justify-content: space-between;
    padding: 0.8%;
}

.err-text {
    /* margin-top: 3vh; */
    /* margin-left: 2vw;
   margin-right: 2vw; */
    /* padding: 0.8%; */
    padding: 0.8%;
}

.but-err {
    display: flex;
    justify-content: center;

}

/* .auto-text{
    width: 12vw;
} */



/* ===============industry Type ====csss================= */
.indu-type {
  
    padding: 0.5%;
}

.industry-text {
    display: flex;
    justify-content: space-between;
    padding: 0.8%;
}

.but-indu {
    display: flex;
    justify-content: center;
    padding: 2%;
}

/* ============Parameter==========css====== */
.para-text {
    display: flex;
    justify-content: space-between;
    padding: 0.8%;
}

.but-para {
    display: flex;
    justify-content: center;
    padding: 2%;
}

.para-typo {


    padding: 0.5%;
    /* background-color: hwb(0 88% 11%); */
}

.Status-text {
    display: flex;
    justify-content: space-between;
    padding: 0.8%;
    margin-top: 3%;
    margin-left: 6%;
}

.statu-but {
    display: flex;
    justify-content: center;
    margin-top: 4vh;
}

.text-sts {
    display: flex;
    justify-content: space-between;
    padding: 0.8%;
   
}

.sts-text {
  
    padding: 0.5%;
   

}


/* ====================equipment type ================= */
.category-text{
 justify-content: center;
}

.cate-gory{
 

    padding: 0.8%;
}

.equipment-type{
    display: flex;
    justify-content: space-between;
    padding: 0.8%;
}


.category-but{
    display: flex;
    justify-content: center;
}

.type-title{
 

    padding: 0.8%;
}

/* .master{
    display: flex;
   
} */

