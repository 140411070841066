.calendar-container {

 padding: 3px;
  font-family: Arial, sans-serif;
  height: 50%;
}

.calendar-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
 color: #ff300b;
}

.calendar-days {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  padding: 5px 0;
  background-color: #ff512c;
  border: 1px solid #ddd;
}

.calendar-day {
  text-align: center;
  font-weight: bold;
  border-right: 1px solid #ddd;
}

.calendar-day:last-child {
  border-right: none;
}

.calendar-grid {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  border: 1px solid #ddd;
  
}

.calendar-cell {
  
  padding: 5px;
  height: 80px;
  border: 1px solid #ddd; /* Adding a border around each cell */
  border-right: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
}

.calendar-cell:last-child {
  border-right: none;
}

.calendar-cell:nth-last-child(-n+7) {
  /* border-bottom: none; */
}

.ticket-code {
  margin-top: 10px;
  font-size: 0.7em;
  color: #555;
}

button {
  padding: 5px 10px;
  font-size: 16px;
}

.empty {
  background-color: #f5f5f5;
}





.fc-event-title {
  white-space: normal !important;  /* Allow text to wrap */
  word-wrap: break-word !important; 


/* Break the text at the end of the line if necessary */
}