.site-over{
    margin-left: 2%;
    font-size: 1.5rem;  
    
}

.sites{
    display: flex;
    justify-content: space-between;
    margin-left: 20%;
    margin-top: -1.6%;
    font-weight: 500;
}

.text{
    margin-top: -2%;
}

.icon-style{
    margin-top: 3%;
}

.view-ticket-card{
    margin-top: 2%;
    display: flex;
    justify-content: space-evenly;
}
.Ticket-details{
    margin-left: 5%;
    width:100%;
    color: rgb(197, 176, 176);
}