@import url('https://fonts.googleapis.com/css2?family=Fira+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Fira+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Roboto+Slab:wght@100;200;300;400;500;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Fira+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Roboto+Slab:wght@100;200;300;400;500;700;800;900&family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap');

p,
table,
th,
td,
h3,
div {
  font-family: 'Inter', 'Ubuntu', 'Roboto Slab', 'Fira Sans' !important;
}

th {
  font-size: 0.95rem;
}

td {
  font-size: 0.75rem !important;
}

body {
  background-color: #f6f8fc;
  height: 100%;
}

.light {
  background-color: #e5e5e5 !important;
}

.cus-search::placeholder {
  font-size: 12px;
  /* Adjust the font size as needed */
}

.dark {
  background-color: black;
}

h3 {
  font-size: 1rem;
}

.highcharts-axis-labels text {
  font-family: 'Inter';
}

.Mui-focusVisible,
.Mui-selected,
.MuiMenuItem-root {
  background-color: transparent;
}

/* body::-webkit-scrollbar {
  width: 5px;
}

body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

body::-webkit-scrollbar-thumb {
  background-color: #919191;
  outline: 1px solid #919191;
  height: auto;
  border-radius: 20px;
} */
.modal-typo {
  text-transform: capitalize;
  opacity: '0.8';
  color: red;
  /* alignItems: "center", */
}

input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.disabled-text-field .MuiInputBase-root.Mui-disabled {
  color: black !important;
  -webkit-text-fill-color: black;
  /* Use lowercase 'webkit' to match browser support */
}

.disabled-text-field .MuiInputLabel-root.Mui-disabled {
  color: black !important;
}
