.us-con-box {
  width: 99%;
  height: 55vh;
}

.user-div {
  margin-left: 2%;
  margin-top: 1%;
}

.above-stack {
  margin-top: 6%;
}

.mob {
  /* margin-right: 23%; */
  width: 30%;
}

.pass {
  width: 30%;
}

.user {
  width: 8.5%;
  margin-right: 6%;
}

.role {
  margin-right: 5%;
  width: 9.7%;
}

.back {
  display: flex;
  justify-content: space-between;
}

.back-button {
  display: flex;
  justify-content: space-between;
  margin-left: 80%;
  margin-top: -3%;
}

.user-details {
  position: relative;
  width: 100%;
  text-transform: capitalize;
  font-size: 1.2rem;
}

@media screen and (min-width: 960px) {
  .user-con {
    margin-right: 5%;
  }
}

@media screen and (min-width: 1240px) {
}

@media screen and (min-width: 1440px) {
  .user-con {
    margin-top: 0%;
  }
}

@media screen and (min-width: 1660px) {
  .user-con {
    margin-right: 5%;
  }
}

@media screen and (min-width: 2560px) {
  .user-con {
    margin-right: 14%;
  }
}

@media screen and (min-width: 2560px) {
  .user-con {
    margin-right: 14%;
  }
}

/*--------------------------------------- New user Config ----------------------------*/

.back {
  display: flex;
  justify-content: space-between;
}
