.view-ticket-card{
    margin-top: 2%;
    display: flex;
    justify-content: space-evenly;
}
.Ticket-details{
    margin-left: 5%;
    width:100%;
    color: rgb(197, 176, 176);
}
.fc-daygrid-event-container {
    max-height: 100px; /* Adjust as needed */
    overflow-y: auto;
  }
  
