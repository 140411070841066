.card-main-align {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  height: 100%;
  width: 100%;
  background-color: "aliceblue";
}

.draggable-item {
  display: flex;
  flex-direction: column;
}

.large-card {
  width: 58vw;
}

.small-card {
  width: 31vw;
  padding-left: 16px;
}

.card-main {
  display: flex;
  gap: 1%;
}

.card-main-card {
  display: flex;
  gap: 1%;
}
